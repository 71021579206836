import { useSelector } from "@xstate/store/react";
import { store } from ".";
import { client } from "../client";
import { useDatabase } from "../database-provider";
import { useRouter } from "../hooks/use-router";

export function useCurrentUser() {
  return useSelector(store, (s) => s.context.currentUser);
}

export function useSessionInfo() {
  return useSelector(store, (s) => s.context.sessionInfo);
}

export function useIsAuthError() {
  return useSelector(
    store,
    (s) => s.context.authStatus === "AUTHENTICATION_ERROR",
  );
}

export function useLastSuccessfulSyncTs() {
  return useSelector(store, (s) => s.context.lastSuccessfulSyncTs);
}

export function useIsSyncing() {
  return useSelector(store, (s) => s.context.syncStatus === "SYNCING");
}

export function useIsSyncAllowed() {
  return useSelector(store, (s) => {
    // For Sync to be enabled, several conditions have to be met:
    const { currentUser, authStatus } = s.context;

    // 1) The current user has to be set, and 2) they must be verified
    if (!currentUser || !currentUser.isVerified) {
      return false;
    }

    // 3) The client has to be authenticated
    return authStatus === "AUTHENTICATED";
  });
}

export function useSyncLog() {
  return useSelector(store, (s) => s.context.syncLog);
}

export function useLogOut() {
  const db = useDatabase();
  const setLocation = useRouter();

  const logOut = async () => {
    const res = await client.logout();

    if (res.isFailure) {
      console.error(res.failure);
      return;
    }

    // Delete all local data
    await db.clearAll();
    store.send({ type: "CLEAR_ALL" });

    // Redirect to home
    setLocation("~/");
  };

  return logOut;
}
